$small-devices-width: 640px;
/**
 * Reset
 * ----------------------------------------------------------------------------
 */
*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}
html {
    font-size: 62.5%;
    box-sizing: border-box;
}
img {
    border: none;
}
img,
embed,
object,
video {
    max-width: 100%;
}
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
    padding: 0 !important;
    border: 0 none;
}
::-webkit-input-placeholder {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
}
::-moz-placeholder {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
}
:-ms-input-placeholder {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
}
html,
body {
    height: 100vh;
    width: 100%;
}

/**
 * Defaults
 * ----------------------------------------------------------------------------
 */
:root {
    --color-primary: #191e27;
    --color-secondary: #f1f3f4;
    --color-secondary--active: #e2e7e9;
    --color-tertiary: #e3e7e8;
    --color-page-background: #ffffff;
    --color-action: #247cf4;
    --color-action--disabled: #f1f3f4;
    --color-action--active: rgba(36, 124, 244, 0.8);
    --color-backdrop: rgba(20, 24, 31, 0.8);
    --color-invalid: #ff0b0b;
    --color-accent: #f4b824;
    --color-chip-background: #247cf4;
    --color-chip-background-disabled: #a8aaaa;
    --color-chip-background-hover: rgba(36, 124, 244, 0.8);
    --color-chip-text: #fff;
    --font-family: Arial;
    --font-size: 1.6rem;
    --font-color-primary: #000000;
    --font-color-disabled: #a8aaaa;
    --font-color-accent: #f4b824;

    --form-column-gap: 30px;
    --form-field-vgap: 10px;
    --form-field-hgap: 30px;
    --form-field-height: 40px;
    --form-field-border-radius: 4px;
    --form-field-background: var(--color-secondary);
    --form-field-font-size: 1.4rem;
    --form-field-padding: 10px;

    --header-height: 130px;
    --work-request-status-min-width: 204px;
}
html {
    background: var(--color-backdrop);
}
body {
    font-size: var(--font-size);
    font-family: var(--font-family);
    color: var(--font-color-primary);
    background: var(--color-page-background);
}
@media screen and (max-width: 1100px) {
    :root {
        --header-height: 86px;
    }
}
@media screen and (max-width: 480px) {
    :root {
        --header-height: 84px;
        --work-request-status-min-width: 104px;
    }
}

/**
 * Themes
 * ----------------------------------------------------------------------------
 */
[data-theme="normal"] {
    --theme-background: var(--color-secondary);
    --theme-background-active: var(--color-secondary--active);
    --theme-border: var(--color-secondary);
    --theme-border-active: var(--color-action);
    --theme-font-color: #000000;
    --theme-disabled-border: var(--color-secondary);
    --theme-disabled-background: transparent;
}
[data-theme="light"] {
    --theme-background: #ffffff;
    --theme-background-active: var(--color-secondary--active);
    --theme-border: #ffffff;
    --theme-border-active: var(--color-action);
    --theme-font-color: #000000;
    --theme-disabled-border: #ffffff;
    --theme-disabled-background: transparent;
}
[data-theme="dark"] {
    --theme-background: #292f38;
    --theme-background-active: #2f373c;
    --theme-border: #3e484f;
    --theme-border-active: var(--color-action);
    --theme-font-color: #ffffff;
    --theme-disabled-border: #3e484f;
    --theme-disabled-background: transparent;
}

/**
 * List Component
 * ----------------------------------------------------------------------------
 */
.component--list {
    --list-padding: 12px;
    --indicator-size: 4px;
    --line-height: 2rem;
    padding-left: var(--list-padding);
    list-style: none;
}
.component--list > li {
    position: relative;
    font-size: 1.4rem;
    line-height: var(--line-height);
}
.component--list > li:before {
    content: "";
    position: absolute;
    width: var(--indicator-size);
    height: var(--indicator-size);
    background: var(--color-primary);
    top: calc(var(--line-height) / 2 - var(--indicator-size) / 2);
    left: calc(var(--list-padding) * -1);
    border-radius: 50%;
}

/**
 * Button
 * ----------------------------------------------------------------------------
 */

.button-cont {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}
.button {
    padding: 10px 17px;
    min-height: 36px;
    border-radius: 4px;
    font-size: 1.4rem;
    border: none;
    outline: none;
    background: transparent;
}
.button--enabled {
    cursor: pointer;
}
.button[data-button-type="text"] {
    background: transparent;
    color: var(--color-action);
}
.button[data-button-type="text"]:hover,
.button[data-button-type="text"]:focus-visible {
    background: rgba(0, 0, 0, 0.05);
}
.button[data-button-type="text"].button--disabled {
    color: var(--font-color-disabled);
    cursor: default;
}
.button[data-button-type="text"][data-button-theme="light"] {
    color: #ffffff;
}
.button[data-button-type="text"][data-button-theme="light"]:hover,
.button[data-button-type="text"][data-button-theme="light"]:focus-visible {
    background: rgba(255, 255, 255, 0.1);
}
.button[data-button-type="text"][data-button-theme="light"].button--disabled {
    color: var(--font-color-disabled);
    background: none;
    cursor: default;
}

.button[data-button-type="contained"] {
    background: var(--color-action);
    color: #ffffff;
    font-weight: 700;
}
.button[data-button-type="contained"]:hover,
.button[data-button-type="contained"]:focus-visible {
    background: var(--color-action--active);
}
.button[data-button-type="contained"].button--disabled {
    background: var(--color-action--disabled);
    color: var(--font-color-disabled);
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.button[data-button-type="icon"] {
    position: relative;
    background: none;
    font-size: 0px;
    border: none;
    outline: none;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 0px;
    color: #585858;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
}
.button[data-button-type="icon"]:hover,
.button[data-button-type="icon"]:focus-visible {
    background-color: rgba(255, 255, 255, 0.1);
    color: #000000;
}
.button[data-button-type="icon"][data-button-theme="light"]:hover,
.button[data-button-type="icon"][data-button-theme="light"]:focus-visible {
    background-color: #f1f3f4;
    color: #000000;
}
.button[data-button-type="icon"].button--disabled {
    color: var(--font-color-disabled);
    cursor: default;
}
.button[data-button-type="icon"].button--disabled:hover {
    color: var(--font-color-disabled);
    background: transparent;
}
.button[data-button-type="outlined"] {
    background: none;
    border: 1px solid var(--color-action);
    color: var(--color-action);
    font-weight: 400;
}
.button[data-button-type="outlined"]:focus-visible,
.button[data-button-type="outlined"]:hover {
    background: var(--color-action);
    color: #ffffff;
}
.button.button--collapse > .inlined-svg--arrow-small {
    color: var(--color-action);
    transition: transform ease 0.25s;
}
.button--collapse:not(.button--collapse--is-collapsed) > .inlined-svg--arrow-small {
    transform: rotate(180deg);
}
button.button--remove {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 0px;
    padding: 0px;
    cursor: pointer;
    font-size: 0;
}
button.button--remove:before {
    content: "";
    display: block;
    width: 8px;
    height: 2px;
    background: #000000;
    border-radius: 500px;
}
button.button--remove:focus-visible,
button.button--remove:hover {
    background: var(--color-secondary);
}
@media screen and (max-width: 480px) {
    .button {
        padding: 10px 12px;
    }
}
@media screen and (max-width: 350px) {
    .button {
        padding: 8px 12px;
        min-height: 32px;
        font-size: 1.2rem;
    }
}

/**
 * Page
 * ----------------------------------------------------------------------------
 */

.page-cont {
    display: grid;
    grid-template-columns: 100%;
}
.page {
    grid-row: 1;
    grid-column: 1;
    position: relative;
    min-height: 100vh;
    background: var(--bg-color);
    display: flex;
    flex-direction: column;
    z-index: 0;
}
.page--enter,
.page--appear {
    opacity: 0;
    transform: translate3d(0, -16px, 0);
}
.page--enter-active,
.page--appear-active,
.page--enter-done,
.page--appear-done {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
    position: relative;
    z-index: 1;
    transition: opacity ease 250ms, transform ease 250ms;
}
.page--enter-done,
.page--appear-done {
    transform: none; // This enables position fixed for children to work. position fixed doesn't work when there is a transform applied
}
.content-wrapper {
    position: relative;
    flex: 1 1 auto;
    background: var(--color-page-background);
    display: flex;
    flex-direction: column;
}
@media (max-width: 640px), (max-height: 640px) {
    .content-wrapper {
        overflow-y: scroll;
    }
}
.inlined-svgs {
    display: none;
}
.page-toolbar {
    grid-area: toolbar;
    min-height: 50px;
    background: var(--color-primary);
    color: #ffffff;
    display: grid;
    grid-template-areas: "title action-buttons buttons";
    grid-template-columns: 1fr auto auto;
    align-items: center;
    padding: 7px 30px;
    position: sticky;
    z-index: 5;
}
.page-toolbar--no-header {
    top: 0;
}
.page-toolbar[data-has-title].page-toolbar[data-has-action-buttons],
.page-toolbar[data-has-title].page-toolbar[data-has-buttons] {
    gap: 20px 40px;
}
.page-toolbar-title {
    display: flex;
    gap: 8px 20px;
    font-size: 1.4rem;
    font-weight: 700;
    grid-area: title;
}
.page-toolbar-action-button-cont {
    grid-area: action-buttons;
}
.page-toolbar-button-cont {
    grid-area: buttons;
}
.page-info-section {
    padding: 30px;
    background: var(--color-secondary);
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
.page-info-section-title {
    font-weight: 700;
    font-size: 1.8rem;
    display: flex;
    gap: 10px;
}
.page-info-section-title a {
    text-decoration: none;
    color: var(--color-action);
}
.page-info-section-title .tag {
    margin-top: 4px;
}
.page-info-section-data-fields {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 20px;
}
.page-content {
    grid-area: content;
    padding: 40px 30px 30px 30px;
}
@media screen and (max-width: 1000px) {
    .page-info-section-data-fields {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
@media screen and (max-width: 800px) {
    .page-info-section-data-fields {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
@media screen and (max-width: 640px) {
    .page-info-section-data-fields {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .page-toolbar {
        padding: 7px 20px;
    }
    .page-toolbar[data-has-title].page-toolbar[data-has-action-buttons],
    .page-toolbar[data-has-title].page-toolbar[data-has-buttons] {
        gap: 10px 20px;
    }
    .page-toolbar[data-has-action-buttons] {
        grid-template-areas:
            "title title"
            "action-buttons buttons";
        grid-template-columns: auto auto;
    }
    .page-info-section {
        padding: 20px;
    }
    .page-toolbar-action-button-cont {
        justify-content: flex-start;
    }
    .page-content {
        padding: 40px 20px 30px 20px;
    }
}
@media screen and (max-width: 480px) {
    .page-info-section-data-fields {
        gap: 15px;
    }
}

/**
 * Modal
 * ----------------------------------------------------------------------------
 */

.modal-cont {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--color-backdrop);
    backdrop-filter: blur(3px);
    z-index: 10;
    transition: opacity ease 250ms, transform ease 250ms;
    opacity: 0;
    overflow: auto;
}
.modal-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.modal-wrapper[data-size="full"] {
    padding: 0;
}
.modal {
    width: 100%;
    min-width: 280px;
    min-height: 150px;
    border-radius: 5px;
    background: #ffffff;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "header"
        "body"
        "buttons";
    transition: opacity ease 250ms, transform ease 250ms;
    outline: none;
    opacity: 0;
    transform: translate3d(0, -10px, 0);
}
.modal[data-size="small"] {
    max-width: 300px;
}
.modal[data-size="normal"] {
    max-width: 500px;
}
.modal[data-size="medium"] {
    max-width: 700px;
}
.modal[data-size="large"] {
    max-width: 1180px;
}
.modal[data-size="larger"] {
    max-width: 80vw;
}
.modal[data-size="full"] {
    max-width: 100vw;
}
.modal-header {
    position: relative;
    padding: 30px 20px 30px 20px;
    background: #f1f3f4;
    border-bottom: 1px solid var(--color-tertiary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-radius: 5px 5px 0px 0px;
    grid-area: header;
}
.modal[data-closable="true"] .modal-header {
    padding: 30px 60px 30px 20px;
}
.modal-title-cont {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.modal-pre-title {
    font-size: 1.2rem;
    font-weight: 700;
}
.modal-title {
    font-size: 1.8rem;
    font-weight: 700;
    display: flex;
    gap: 8px;
}
.modal-sub-title {
    font-size: 1.4rem;
}
.modal-close-button {
    position: absolute;
    z-index: 3;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    font-size: 0;
    background-image: url("../images/close.svg");
    background-position: center;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
}
.modal-close-button:hover,
.modal-close-button:focus-visible {
    background-color: #e5e7e8;
}
.modal-body {
    position: relative;
    padding: 20px;
    grid-area: body;
    font-size: 1.4rem;
    line-height: 2.2rem;
}
.modal-button-cont {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    margin-top: 0px;
    gap: 10px;
    grid-area: buttons;
}
.modal-header .modal-button-cont {
    margin: 0px;
}
.modal--enter-active,
.modal--appear-active,
.modal--enter-done,
.modal--appear-done {
    opacity: 1;
}
.modal--enter-active .modal,
.modal--appear-active .modal,
.modal--enter-done .modal,
.modal--appear-done .modal {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
    position: relative;
    z-index: 1;
}
.modal-loader {
    grid-area: body;
}

.form-field-content.collapsible-form-field .modal-loader {
    padding-top: 14px;
}

/**
 * Grid
 * ----------------------------------------------------------------------------
 */
.grid-cont {
    display: grid;
    grid-template-columns: 1fr;
}
.grid-cont[data-gap="small"] {
    gap: 10px;
}
.grid-cont[data-gap="normal"] {
    gap: 20px;
}
.grid-cont[data-gap="medium"] {
    gap: 40px;
}
.grid-cont[data-gap="large"] {
    gap: 60px;
}
.grid {
    display: grid;
    gap: 20px;
    width: 100%;
}
.grid[data-justify-items="start"] {
    justify-items: start;
}
.grid[data-justify-items="center"] {
    justify-items: center;
}
.grid[data-justify-items="end"] {
    justify-items: end;
}
.grid[data-justify-items="stretch"] {
    justify-items: stretch;
}
.grid[data-gap="small"] {
    gap: 10px;
}
.grid[data-gap="normal"] {
    gap: 20px;
}
.grid[data-gap="medium"] {
    gap: 40px;
}
.grid[data-gap="large"] {
    gap: 60px;
}
.grid[data-columns="1"] {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid[data-columns="2"] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid[data-columns="3"] {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid[data-columns="4"] {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid[data-columns="5"] {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid[data-columns="6"] {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid[data-columns="7"] {
    grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid[data-columns="8"] {
    grid-template-columns: repeat(8, minmax(0, 1fr));
}
@media screen and (max-width: 800px) {
    .grid[data-columns="4"] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid[data-columns="5"] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid[data-columns="6"] {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid[data-columns="7"] {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid[data-columns="8"] {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .grid[data-columns-md="1"] {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid[data-columns-md="2"] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid[data-columns-md="3"] {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid[data-columns-md="4"] {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .grid[data-columns-md="5"] {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid[data-columns-md="6"] {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .grid[data-columns-md="7"] {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .grid[data-columns-md="8"] {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}
@media screen and (max-width: 500px) {
    .grid[data-columns] {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .grid[data-columns-sm="1"] {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid[data-columns-sm="2"] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid[data-columns-sm="3"] {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid[data-columns-sm="4"] {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .grid[data-columns-sm="5"] {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid[data-columns-sm="6"] {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .grid[data-columns-sm="7"] {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .grid[data-columns-sm="8"] {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}
/**
 * CheckBox
 * ----------------------------------------------------------------------------
 */
.checkbox {
    display: flex;
    line-height: 2.4rem;
    position: relative;
    cursor: pointer;
    gap: 16px;
    width: fit-content;
}
.checkbox-input-field {
    opacity: 0;
    width: 24px;
    height: 24px;
    position: absolute;
}
.checkbox-input-mask {
    width: 24px;
    height: 24px;
    background-color: #f1f3f4;
    border: 1px solid #acacac;
    border-radius: 4px;
    position: relative;
    transition: background-color ease 0.2s;
    flex: 0 0 auto;
}
.checkbox-input-field:checked + .checkbox-input-mask {
    background-color: var(--color-action);
    background-image: url("../images/checkmark.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: none;
}
.checkbox-label {
    font-size: 1.4rem;
    user-select: none;
}
// Focus
.checkbox .checkbox-input-field:focus-visible + .checkbox-input-mask {
    border: 1px solid #000000;
    background-color: #f1f3f4;
}
.checkbox .checkbox-input-field:focus-visible:checked + .checkbox-input-mask {
    border: 1px solid #000000;
    background-color: var(--color-action--active);
}
// Hover
.checkbox:hover .checkbox-input-field + .checkbox-input-mask {
    background-color: #d9dadb;
}
.checkbox:hover .checkbox-input-field:checked + .checkbox-input-mask {
    background-color: var(--color-action--active);
}
// Disabled
.checkbox--disabled {
    cursor: default;
}
.checkbox--disabled .checkbox-input-field:checked + .checkbox-input-mask,
.checkbox--disabled:hover .checkbox-input-field:checked + .checkbox-input-mask,
.checkbox--disabled .checkbox-input-field + .checkbox-input-mask,
.checkbox--disabled:hover .checkbox-input-field + .checkbox-input-mask {
    background-color: #bfbfbf;
    border: none;
}

/**
 * Radio
 * ----------------------------------------------------------------------------
 */
.radio {
    display: flex;
    line-height: 2.4rem;
    position: relative;
    cursor: pointer;
}
.radio-input-field {
    opacity: 0;
    width: 20px;
    height: 20px;
    position: absolute;
}
.radio-input-mask {
    width: 20px;
    height: 20px;
    border: 2px solid #707070;
    border-radius: 50%;
    position: relative;
    transition: background-color ease 0.2s;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.radio-input-field:checked + .radio-input-mask {
    background-position: center;
    background-repeat: no-repeat;
}
.radio-input-field:checked + .radio-input-mask:before {
    display: block;
    content: "";
    background-color: var(--color-action);
    height: 12px;
    width: 12px;
    border-radius: 50%;
}
.radio-label-cont {
    margin-left: 16px;
    font-size: 1.4rem;
    user-select: none;
    line-height: 2rem;
}
.radio-sub-label {
    font-size: 1rem;
    line-height: 1.3rem;
    color: #585858;
}
.radio-list {
    .radio-column-title {
        font-weight: normal;
        padding-right: 8px;
    }
}
// Focus
.radio .radio-input-field:focus-visible + .radio-input-mask {
    border: 2px solid #000000;
    background-color: #f1f3f4;
}
.radio .radio-input-field:focus-visible:checked + .radio-input-mask {
    border: 2px solid #000000;
}
.radio .radio-input-field:focus-visible:checked + .radio-input-mask:before {
    background-color: var(--color-action--active);
}
// Hover
.radio:hover .radio-input-field + .radio-input-mask {
    background-color: #d9dadb;
}
.radio:hover .radio-input-field:checked + .radio-input-mask:before {
    background-color: var(--color-action--active);
}
// Disabled
.radio--disabled {
    cursor: default;
}
.radio--disabled .radio-input-field:checked + .radio-input-mask,
.radio--disabled:hover .radio-input-field:checked + .radio-input-mask,
.radio--disabled .radio-input-field + .radio-input-mask,
.radio--disabled:hover .radio-input-field + .radio-input-mask {
    background-color: #bfbfbf;
    border: none;
}

/**
 * Header
 * ----------------------------------------------------------------------------
 */

header {
    background: var(--color-primary);
    top: 0;
    position: sticky;
    position: -webkit-sticky;
}
.page--enter-done header,
.page--appear-done header {
    z-index: 10;
}
header .logo {
    display: none;
}
.header-toolbar {
    padding: 5px 4px 5px 10px;
    display: flex;
    justify-content: space-between;
}
.header-toolbar-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-toolbar-section--actions {
    gap: 12px;
    width: fit-content;
}
.header-toolbar-section.header-toolbar-section--actions .popover-cont {
    width: 100%;
    text-align: center;
    min-width: 99px;
}
.header-toolbar-version {
    font-size: 0.9rem;
    color: #fff;
    margin-left: 18px;
}
.header-toolbar-user-message {
    color: #fff;
    font-size: 11px;
    margin-left: 9px;
    margin-bottom: -6px;
}
.header-toolbar-link {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    background: #595959;
    border: 1px solid #2b2b2b;
    display: inline-block;
    border-radius: 500px;
    padding: 3px 16px;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    width: 100%;
    text-align: center;
    white-space: nowrap;
}
.header-toolbar-link:hover {
    background: #737373;
}
.header-navigation-menu {
    margin: 1px 5px 0px 7px;
    display: flex;
}
.navigation-tab {
    position: relative;
    margin: 0px 3px;
    padding: 12px 12px 15px;
    background: #191e27;
    border-radius: 5px 5px 0px 0px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    color: #f4b824;
    display: grid;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-family: var(--font-family);
}
.navigation-tab-label,
.navigation-tab:before {
    grid-row: 1;
    grid-column: 1;
}
.navigation-tab:before {
    content: attr(data-label);
    visibility: hidden;
}
.navigation-tab--active {
    padding: 12px 12px 15px 12px;
    background: #191e27;
    text-decoration: underline;
    text-decoration-thickness: 2.2px;
    text-underline-offset: 8px;
    color: white;
}

.navigation-tab:hover {
    padding: 12px 12px 15px 12px;
    background: #191e27;
    text-decoration: underline;
    text-decoration-thickness: 2.2px;
    text-underline-offset: 8px;
}
.navigation-tab:hover:after,
.navigation-tab--active:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 0px;
    background: #f1f3f4;
}
.header-sub-nav-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f1f3f4;
}
.header-sub-nav {
    padding: 6px 10px 0px 10px;
    display: flex;
    flex: 1 1 0px;
}
.header-sub-nav-link {
    padding: 10px 10px;
    font-size: 1.4rem;
    color: #505050;
    text-decoration: none;
}
.header-sub-nav-link--active .header-sub-nav-link-label,
.header-sub-nav-link:hover .header-sub-nav-link-label {
    display: inline-block;
    color: #000000;
    padding-bottom: 2px;
    border-bottom: 2px solid currentColor;
}
.header-filter-button {
    width: 40px;
    height: 40px;
    outline: none;
    background: none;
    border: none;
    font-size: 0;
    border-radius: 4px;
    margin: 0px 10px 0px 0px;
    cursor: pointer;
    color: #707070;
    flex: 0 0 auto;
}
.header-filter-button:hover,
.header-filter-button:focus-visible {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000000;
}
.header-filter-button--close {
    background-image: url("../images/close.svg");
}
.header-filter-button-label {
    float: left;
    margin-left: -115px;
    position: relative;
    top: 12px;
    font-size: 14px;
}
.header-filter-button-label.closed {
    top: unset;
}
.mobile-menu-button {
    display: none;
    width: 30px;
    height: 30px;
    position: relative;
    background: none;
    outline: none;
    border: none;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    row-gap: 4px;
    border-radius: 4px;
}
.mobile-menu-button:focus-visible {
    background: rgba(255, 255, 255, 0.25);
}
.mobile-menu-button-stripe {
    height: 2px;
    width: 20px;
    background: white;
    border-radius: 4px;
}

.header-asset-search-cont {
    padding-left: 20px;
    margin-right: 3px;
    margin-left: auto;
    margin-top: 1px;
    width: 100%;
    max-width: 334px;
}
.header-asset-search-cont .text-input .inlined-svg--search {
    color: #c7c7c7;
}
.header-asset-search.searchable-text-input > .text-input {
    height: 34px;
    background: #595959;
}
.header-asset-search.searchable-text-input {
    width: 316px;
}
.header-asset-search.searchable-text-input {
    width: 316px;
}
.header-asset-search.searchable-text-input .text-input-field {
    color: #ffffff;
    border: 1px solid #595959;
}
.header-asset-search.searchable-text-input .text-input .text-input-field::placeholder,
.header-asset-search.searchable-text-input .text-input .text-input-field::-webkit-input-placeholder,
.header-asset-search.searchable-text-input .text-input .text-input-field::-moz-placeholder {
    color: white;
    font-size: 14px;
}

.header-asset-search-result-count {
    font-weight: 700;
    font-size: 1rem;
    cursor: default;
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.header-asset-search-result-count > button {
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    font-weight: inherit;
}
.header-asset-search .searchable-text-input-option {
    display: flex;
    align-items: center;
    cursor: default;
    padding: 0px;
    height: 40px;
}
.header-asset-search .header-asset-search-result {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 1.2rem;
    line-height: 1.4rem;
}
.header-asset-search-result-text-cont {
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header-asset-search-result-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (max-width: 1100px) {
    .header-navigation-menu {
        display: flex;
        visibility: hidden;
        opacity: 0;
        transform: translate(0px, -10px);
        transition: visibility ease 0.3s, opacity ease 0.3s, transform ease 0.3s;
        position: absolute;
        right: 0;
        width: 80vw;
        max-width: 150px;
        flex-direction: column;
        top: 40px;
        background: #d8dadb;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        row-gap: 0px;
        margin: 8px 10px;
    }
    .navigation-tab,
    .navigation-tab--active,
    .navigation-tab:hover {
        border-radius: 4px;
        padding: 10px 12px 10px 12px;
    }
    .navigation-tab:hover:after,
    .navigation-tab--active:after {
        display: none;
    }
    .header--mobile-menu-open .header-navigation-menu {
        visibility: visible;
        opacity: 1;
        transform: translate(0px, 0px);
    }
    .header-toolbar-section--actions {
        display: none;
    }
    .mobile-menu-button {
        display: grid;
    }
    .header-asset-search-cont {
        padding-left: 0;
    }
}
@media screen and (max-width: 640px) {
    header .logo {
        display: block;
    }
    header .logo--text {
        display: none;
    }
    .header-sub-nav-link {
        padding: 10px 8px;
    }
}
@media screen and (max-width: 480px) {
    .header-sub-nav-link {
        padding: 10px 6px;
        font-size: 1.2rem;
    }
}
.header--hide {
    display: none !important;
}

/**
 * Page Not Found
 * ----------------------------------------------------------------------------
 */
.page--page-not-found .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

/**
 * Widget
 * ----------------------------------------------------------------------------
 */
.widget-settings-button {
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 0;
    border: none;
    background: none;
    background-position: center;
    top: 10px;
    right: 10px;
    border-radius: 4px;
    color: #707070;
    outline: none;
}
.widget-settings-button[data-theme="light"]:focus-visible,
.widget-settings-button[data-theme="light"]:hover {
    background-color: rgba(255, 255, 255, 0.07);
    color: #ffffff;
}
.widget-settings-button[data-theme="dark"]:focus-visible,
.widget-settings-button[data-theme="dark"]:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000000;
}

/**
 * Status Widget
 * ----------------------------------------------------------------------------
 */
.status-widget {
    background: var(--color-primary);
    display: flex;
    position: relative;
    min-height: 200px;
}
.status-widget:before {
    position: absolute;
    content: "Loading...";
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: visibility ease 0.25s, opacity ease 0.25s;
}
.status-widget--loading:before {
    visibility: visible;
    opacity: 1;
}
.status-widget-group-cont {
    display: flex;
    flex: 1 1 auto;
}
.status-widget-group {
    position: relative;
    flex: 1 1 0%;
    padding: 32px 20px 40px 20px;
}

.service-quote-filters .status-widget-group {
    position: relative;
    flex: 1 1 0%;
    padding: 0px 20px 20px 20px;
}

.service-quote-filters.status-widget {
    min-height: auto;
}

.page--service-quotes .quick-filters{
    margin-bottom: 0px;
}
.status-widget-group-name {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 26px;
}
.status-widget-group:nth-last-child(n + 2):after {
    content: "";
    display: block;
    width: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: #292f38;
}
.status-widget-group-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, var(--circle-button-container-size));
    gap: var(--circle-button-gap);
}

.service-quote-filters .status-widget-group-items {
    grid-template-columns: repeat(auto-fit, var(--service-quote-circle-containter-size));
}

@media screen and (max-width: 800px) {
    .status-widget-group-cont {
        flex-wrap: wrap;
    }
    .status-widget-group {
        flex: 0 0 100%;
    }
    .status-widget-group:nth-last-child(n + 2):after {
        top: auto;
        left: 0;
        height: 2px;
        width: auto;
    }
}

/**
 * Circle Button
 * ----------------------------------------------------------------------------
 */
:root {
    --circle-button-container-size: 96px;
    --circle-button-size: 50px;
    --circle-button-gap: 20px 10px;
    --service-quote-circle-containter-size: 125px;
}

.circle-button {
    width: var(--circle-button-container-size);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #879aa0;
    text-decoration: none;
    border: none;
    background: none;
    outline: none;
}
.circle-button-circle {
    position: relative;
    background: rgba(118, 135, 141, 0.4);
    width: var(--circle-button-size);
    height: var(--circle-button-size);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    font-size: 1.4rem;
    font-weight: 700;
    color: #ffffff;
}
.circle-button--is-interactive {
    cursor: pointer;
}
.circle-button--is-interactive.circle-button--active .circle-button-circle:before,
.circle-button--is-interactive:hover .circle-button-circle:before,
.circle-button--is-interactive:focus-visible .circle-button-circle:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.15);
}
.circle-button--is-interactive.circle-button--active,
.circle-button--is-interactive:hover .circle-button-label,
.circle-button--is-interactive:focus-visible .circle-button-label {
    color: #ffffff;
}
.circle-button-circle-label {
    position: relative;
    font-family: var(--font-family);
}
.circle-button-label {
    font-size: 1.5rem;
}
.circle-button[data-color="transparent"] .circle-button-circle {
    border: 2px solid #76878d;
}
.circle-button[data-color="transparent"]:hover .circle-button-circle {
    border: 2px solid #ffffff;
}
.circle-button[data-color="transparent"]:hover .circle-button-circle:before {
    background-color: transparent;
}
.circle-button--add .circle-button-circle {
    background-image: url("../images/add.svg");
    background-position: center;
    background-repeat: no-repeat;
}
@media screen and (max-width: 640px) {
    :root {
        --circle-button-gap: 20px 10px;
        --circle-button-container-size: 76px;
        --circle-button-size: 40px;
    }
    .circle-button-label {
        font-size: 1.2rem;
    }
}

.service-quote-filters .circle-button {
    width: var(--service-quote-circle-containter-size);
}

.service-quote-filters .circle-button-circle {
    margin-bottom: 7px;
}
.page-toolbar-action-button-cont .circle-button-circle {
    width: 25px;
    height: 25px;
    font-size: 0.5rem !important;
    font-weight: 500 !important;
}

/**
 * Quick Filters
 * ----------------------------------------------------------------------------
 */
.quick-filters {
    position: relative;
    padding: 30px 8px;
    background-color: var(--color-primary);
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}
.quick-filters__buttons {
    display: grid;
    gap: var(--circle-button-gap);
    grid-template-columns: repeat(auto-fit, var(--circle-button-container-size));
}
.quick-filters__actions {
    padding-left: 20px;
    grid-column: 2;
}
.quick-filters .multi-select {
    display: none;
}

.multi-select.assigned-to-filter, .multi-select.quick-filter-dropdown{
    display: block;
}
@media screen and (max-width: 640px), (max-height: 640px) {
    .quick-filters {
        padding: 16px 8px;
    }
    .quick-filters .quick-filters__buttons {
        display: none;
    }
    .quick-filters .multi-select {
        display: block;
    }
}

/**
 * Status Widget Settings Modal
 * ----------------------------------------------------------------------------
 */
.status-widget-settings-modal-content {
    display: flex;
    font-size: 1.4rem;
}
.status-widget-settings-modal-group {
    flex: 1 1 auto;
}
.status-widget-settings-modal-group-label {
    font-weight: 700;
}

/**
 * Table
 * ----------------------------------------------------------------------------
 */
.table {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.table .table-data-cont {
    position: relative;
    min-height: 400px;
    flex: 1 1 auto;
    display: flex;
}

.page--asset-work-requests--multi-table .table-data-cont {
    min-height: 0;
}
.page--asset-work-requests--multi-table:first-of-type .table-data-cont {
    padding-bottom: 100px;
}

.page--work-orders .table .table-data-cont,
.page--work-requests .table .table-data-cont {
    height: 0;
    overflow-y: auto;
}

.page--work-orders .table.manage-filter-presets .table-data-cont,
.page--work-requests .table.manage-filter-presets .table-data-cont {
    height: unset;
}

.page--work-orders .table--sortable .table-cell--head,
.page--work-requests .table--sortable .table-cell--head {
    position: sticky;
    top: 0;
}

.button-cont.user-work-requests-button-cont,
.button-cont.user-work-orders-button-cont {
    align-self: center;
}

.button-cont.user-work-requests-button-cont .button,
.button-cont.user-work-orders-button-cont .button {
    margin-bottom: 10px;
}

.button-cont.user-work-requests-button-cont .button.button--enabled,
.button-cont.user-work-orders-button-cont .button.button--enabled {
    background-color: var(--color-primary);
}

.button-cont.user-work-requests-button-cont .button.button--disabled,
.button-cont.user-work-orders-button-cont .button.button--disabled {
    background-color: var(--color-primary);
}

.table .table-data {
    display: grid;
    overflow-x: auto;
    table-layout: fixed;
    border-collapse: collapse;
    flex: 1 1 auto;
    align-content: flex-start;
}
.table-cell--head__content {
    white-space: nowrap;
}
.table-cell[data-alignment="start"] .table-cell--head__content,
.table-cell[data-alignment="start"] .table-cell--body__content {
    justify-content: flex-start;
    text-align: start;
}
.table-cell[data-alignment="center"] .table-cell--head__content,
.table-cell[data-alignment="center"] .table-cell--body__content {
    justify-content: center;
    text-align: center;
}
.table-cell[data-alignment="end"] .table-cell--head__content,
.table-cell[data-alignment="end"] .table-cell--body__content {
    justify-content: flex-end;
    text-align: end;
}
.table-cell {
    border-bottom: 1px solid var(--color-tertiary);
}
.table-cell--body:not(.table-cell--no-hover-background):hover {
    background: rgba(237, 237, 237, 0.5);
}
.table thead,
.table tbody,
.table tr {
    display: contents;
}
.table-cell--more:hover {
    background: none;
}
.table .table-cell__content {
    background: transparent;
    padding: 5px 10px;
    border: none;
    font-size: 1.4rem;
    min-height: 50px;
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.table .table-cell--head__content {
    font-weight: 700;
    font-size: 1.2rem;
}

/**
 * Sortable Table
 * ----------------------------------------------------------------------------
 */
.table--sortable .table-cell__content {
    padding: 5px 10px;
    min-height: 50px;
    font-size: 1.4rem;
    border-right: 2px solid #ffffff;
}
.table--sortable {
    z-index: 0;
}
.table--sortable .table-cell--head {
    border-bottom: none;
    position: relative;
    z-index: 2;
}
.table--sortable .table-cell--head__content {
    padding: 10px;
    background: #f1f3f4;
    min-height: 54px;
    font-size: 1.2rem;
}
.table--sortable .table-cell[data-sortable] .table-cell--head__content:hover,
.table--sortable .table-cell[data-sortable] .table-cell--head__content:focus-visible {
    background: #e8ebed;
}
.table--sortable .table-cell--head[data-sortable] .table-cell--head__content {
    cursor: pointer;
}
.table--sortable .table-cell[data-row-end] .table-cell__content {
    border-right: none;
}
.sortable-table-filters-actions-cont {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}
.sortable-table-filters-cont {
    padding: 10px 24px;
}
.table-sort-icon {
    background-image: url("../images/sort-inactive.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 9px;
    height: 6px;
    font-size: 0;
    margin-top: 5px;
    margin-left: 5px;
}
.table-cell[data-alignment="end"] .table-sort-icon {
    margin-left: auto;
    margin-right: 5px;
}
.table-sort-icon[data-order="desc"] {
    background-image: url("../images/sort-active.svg");
}
.table-sort-icon[data-order="asc"] {
    transform: rotate(180deg);
    background-image: url("../images/sort-active.svg");
}
.table--sortable a {
    color: var(--color-action);
    text-decoration: none;
}
.sortable-table-actions-cont {
    display: flex;
    align-items: center;
    padding: 10px 24px;
    gap: 25px;
}
.sortable-table-pagination-cont {
    display: flex;
    align-items: center;
    gap: 20px;
}
.sortable-table-button {
    position: relative;
    background: none;
    font-size: 0px;
    border: none;
    outline: none;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 8px;
    color: #585858;
}
.sortable-table-button .inlined-svg {
    position: absolute;
    top: 0;
    left: 0;
}
.sortable-table-button:hover,
.sortable-table-button:focus-visible {
    background-color: #f1f3f4;
    color: #000000;
}
.sortable-table-button--disabled {
    color: var(--font-color-disabled);
    cursor: default;
}
.sortable-table-button--disabled:hover {
    color: var(--font-color-disabled);
    background: transparent;
}
.sortable-table-pagination-count-button {
    width: auto;
    height: auto;
    font-size: 1.2rem;
    position: relative;
    white-space: nowrap;
}
.sortable-table-pagination-count-cont {
    position: relative;
}
.table--sortable .pagination-page-selector {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    position: absolute;
    visibility: hidden;
    right: 0px;
    top: 100%;
    opacity: 0;
    transition: visibility ease 0.2s, opacity ease 0.2s, transform ease 0.2s;
    z-index: 1;
    align-items: center;
    border: 1px solid var(--color-tertiary);
    background: var(--color-secondary);
    border-radius: 4px;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
    transform: translate(0, -10px);
    min-width: 125px;
    max-height: 300px;
    overflow: auto;
}
.table--sortable .pagination--show-page-selector .pagination-page-selector {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0px);
}
.sortable-table-button--page-selector {
    font-size: 1.2rem;
    color: var(--color-action);
}
.sortable-table-button--page-selector.sortable-table-button--disabled {
    color: var(--font-color-disabled);
}
.sortable-table-button--page-selector.sortable-table-button--disabled:hover {
    background: var(--color-secondary);
    color: var(--font-color-disabled);
}
.sortable-table-button--view-all {
    grid-column: span 10;
    width: auto;
}
.sortable-table-button--page-selector:hover,
.sortable-table-button--page-selector:focus-visible {
    background: var(--color-tertiary);
    color: var(--color-action);
}
.sortable-table-pagination-count--current {
    font-weight: 700;
    color: #585858;
}
.sortable-table-pagination-count--total {
    color: #585858;
}
.sortable-table-pagination-next-button {
    transform: rotate(180deg);
}
.table-empty-view {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 800px) {
    .sortable-table-filters-actions-cont {
        flex-wrap: wrap;
    }
    .sortable-table-filters-cont {
        flex: 0 0 100%;
        padding: 10px 20px;
    }
    .sortable-table-filters-cont:empty {
        display: none;
    }
    .sortable-table-actions-cont {
        order: -1;
        border-bottom: 1px solid var(--color-tertiary);
        flex: 0 0 100%;
        display: flex;
        padding: 5px 20px;
        gap: 10px;
    }
    .sortable-table-pagination-cont {
        margin-left: auto;
        gap: 10px;
    }
    .table--sortable .pagination-page-selector {
        grid-template-columns: repeat(5, 1fr);
    }
    .sortable-table-button--view-all {
        grid-column: span 5;
    }
}
@media screen and (max-width: 640px) {
    .table--sortable .table-cell__content {
        padding: 4px 6px;
        font-size: 1.2rem;
    }
}

/**
 * Form Table
 * ----------------------------------------------------------------------------
 */
.table--form {
    padding: 20px;
    background: var(--color-secondary);
    border-radius: 4px;
}
.table--form .table-data-cont {
    min-height: 0px;
}
.table--form .table-data {
    gap: 8px 20px;
    align-items: flex-start;
}
.table--form .table-cell {
    border: none;
}
.table--form .table-cell__content {
    padding: 0px;
    min-height: 0px;
}

/**
 * History Table
 * ----------------------------------------------------------------------------
 */
.history-table.history-table--mobile {
    padding: unset;
    background: unset;
    border-radius: unset;
}
.history-table.history-table--mobile .table-data-cont {
    min-height: 400px;
}
.history-table.history-table--mobile .table-data {
    gap: unset;
    align-items: unset;
}
.history-table.history-table--mobile .table-cell {
    display: grid;
    overflow-x: auto;
    table-layout: fixed;
    border-collapse: collapse;
    flex: 1 1 auto;
    align-content: flex-start;
    border: initial;
}
.history-table.history-table--mobile .table-cell__content {
    padding: 5px 10px;
    min-height: 50px;
}
.history-table.history-table--mobile .history-table__description-content--mobile-cont .table-cell__content {
    min-height: 0px;
    padding: 1px 20px;
}

.history-table .table-data {
    align-items: center;
}
.history-table__date {
    white-space: pre;
}
.table-cell--body__content .history-table__description {
    white-space: initial;
    word-break: break-word;
}
@media screen and (max-width: 640px) {
    .history-table .table-data {
        overflow: hidden;
    }
    .history-table .table-cell--body__content {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
    .history-item-description-button {
        cursor: pointer;
        display: block;
        width: 40px;
        height: 40px;
        background-image: url(/images/arrow-small.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
    .history-table__description-content--mobile {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
        width: 100%;
        padding-bottom: 8px;
    }
    .history-table__description-content--mobile-cont {
        position: relative;
    }
    .history-table__description-content--mobile-cont:after {
        content: "";
        width: 100%;
        border-bottom: 1px solid var(--color-tertiary);
    }
}

.history-table.history-table--mobile .table-row:last-child .history-table__description-content--mobile-cont:after {
    border-bottom: none;
}

.history-table.history-table--mobile .history-item-description-button--open {
    transform: scaleY(-1);
}

.collapsible-form-field-header-collapse-button {
    cursor: pointer;
    display: block;
    width: 40px;
    height: 40px;
    background-image: url(/images/arrow-small.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: -8px;
}

.collapsible-form-field-header-collapse-button--open {
    transform: scaleY(-1);
}

/**
 * Collapsible Table
 * ----------------------------------------------------------------------------
 */

.table--collapsible .table-data-cont {
    min-height: 0;
}
.table--collapsible .table-cell--collapsible .table-cell__content {
    padding: 0px;
    min-height: 0px;
}
.table--collapsible .table-cell--head {
    background: var(--color-secondary);
}
.table--collapsible .table-cell--head:first-child {
    border-radius: 500px 0px 0px 500px;
}
.table--collapsible > .table-data-cont > .table-data > tbody > tr > .table-cell:first-child > .table-cell__content,
.table--collapsible > .table-data-cont > .table-data > thead > tr > .table-cell:first-child > .table-cell__content {
    padding-left: 20px;
}
.table--collapsible .table-cell--head:last-child {
    border-radius: 0px 500px 500px 0px;
}
.table--collapsible > .table-data-cont > .table-data > thead > tr > .table-cell--head:last-child > .table-cell__content,
.table--collapsible
    > .table-data-cont
    > .table-data
    > tbody
    > tr
    > .table-cell--body:nth-last-child(2)
    > .table-cell__content {
    padding-right: 20px;
}
.table--collapsible .table-cell--head,
.table-cell--collapsible {
    border-bottom: none;
}
.table--collapsible .table-row:last-child .table-cell {
    border-bottom: none;
}
.table-cell--collapsible .component--collapsible {
    width: 100%;
}
.table-cell--collapsible:hover {
    background: none;
}
.table-cell--collapsible__content {
    padding: 36px 0px;
    border-bottom: 1px solid var(--color-tertiary);
}
.table--collapsible .table-cell--body:hover {
    background: transparent;
}

/**
 * Collapsible
 * ----------------------------------------------------------------------------
 */
.component--collapsible {
    height: 0px;
    overflow: hidden;
    will-change: height;
    transition: height ease-in-out 500ms;
}

/**
 * Popover
 * ----------------------------------------------------------------------------
 */
.popover-cont {
    position: relative;
    display: flex;
}
.popover-button {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-size: 0;
}
.popover-button:hover,
.popover-button:focus-visible {
    background-color: #f1f3f4;
}
.popover-button:before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
}
.popover {
    opacity: 0;
    transform: translate(0, -10px);
    visibility: hidden;
    transition: opacity ease 0.25s, transform ease 0.25s, visibility ease 0.25s;
    position: absolute;
    right: 0;
    top: 100%;
    background: #292f38;
    border-radius: 4px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    width: 200px;
    min-height: 40px;
    overflow: hidden;
    z-index: 2;
    padding: 2px;
}
.popover--position-top {
    top: auto;
    bottom: 100%;
    transform: translate(0, 10px);
}
.popover--position-end {
    right: auto;
    left: 0;
}
.popover--active .popover {
    opacity: 1;
    transform: translate(0, 0px);
    visibility: visible;
}
.popover-item {
    display: flex;
    width: 100%;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    padding: 10px 15px;
    min-height: 40px;
    cursor: pointer;
    text-decoration: none;
}
.popover-item:not([disabled]):hover,
.popover-item:not([disabled]):focus-visible {
    background: #191e27;
}
.popover-item-icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 15px;
}

.popover-item .popover-item-icon .inlined-svg {
    color: white;
    margin-left: -11px;
    margin-top: -9px;
}

.popover-item-label {
    font-size: 1.4rem;
    color: #ffffff;
}
.popover-item[disabled] .popover-item-label {
    color: #acacac;
}

/**
 * Chip
 * ----------------------------------------------------------------------------
 */
.chip-cont {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.chip {
    background: var(--color-chip-background);
    font-size: 1.2rem;
    border-radius: 500px;
    padding: 3px 6px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    gap: 8px;
}
.chip-category {
    color: var(--color-chip-text);
}
.chip-value {
    color: var(--color-chip-text);
}
.chip-remove {
    cursor: pointer;
    height: 14px;
    width: 14px;
    font-size: 0;
    background: none;
    outline: none;
    border: none;
    background-image: url("../images/filter-remove-white.svg");
    background-size: 9px 9px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-left: auto;
}
.chip-remove:focus-visible {
    background-color: var(--color-chip-background-hover);
}
.chip:hover {
    background: var(--color-chip-background-hover);
}
.chip.disabled {
    background: var(--color-chip-background-disabled);
}
.chip-tooltip {
    position: absolute;
    width: 100%;
    top: calc(100%);
    left: 0px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: visibility ease 300ms, opacity ease 300ms;
}
.chip-tooltip ul {
    margin-top: 5px;
    list-style-type: none;
    background: #f1f3f4;
    border-radius: 4px;
    box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.5);
    max-height: 200px;
    overflow: auto;
}
.chip-tooltip li {
    padding: 4px;
    border-bottom: 1px solid #d0d0d0;
}
.chip-tooltip li:last-child {
    border-bottom: none;
}
.chip:hover .chip-tooltip {
    transition-delay: 1000ms;
    visibility: visible;
    opacity: 1;
}

/**
 * DataField
 * ----------------------------------------------------------------------------
 */

.data-field {
    padding: 8px;
}
.data-field[data-has-buttons] {
    display: grid;
    align-content: flex-start;
    column-gap: 8px;
    grid-template-areas:
        "label buttons"
        "value buttons";
    grid-template-columns: 1fr auto;
}
.data-field-label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 700;
    margin-bottom: 6px;
    grid-area: label;
}
.data-field-value {
    font-size: 1.4rem;
    line-height: 1.8rem;
    min-height: 14px;
    grid-area: value;
    display: flex;
    gap: 10px;
}
.data-field-value a {
    text-decoration: none;
    color: var(--color-action);
}
.data-field-button-cont {
    grid-area: buttons;
}
.data-field--editable .button:hover,
.data-field--editable .button:focus-visible {
    background-color: var(--color-tertiary);
    color: #000000;
}
.data-field--editable .text-input {
    height: auto;
}
.data-field--editable .text-input-field {
    padding: 0px;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.data-field--editable .text-input-field:focus-visible {
    border-top: none;
    border-left: none;
    border-right: none;
}
@media screen and (max-width: 480px) {
    .data-field-value {
        word-wrap: break-word;
    }
}

/**
 * Work Requests
 * ----------------------------------------------------------------------------
 */
.work-requests-quick-filters .circle-button--add {
    width: 120px;
}
@media screen and (max-width: 640px) {
    .work-requests-quick-filters .circle-button--add {
        width: var(--circle-button-size);
    }
    .work-requests-quick-filters .circle-button--add .circle-button-label {
        display: none;
    }
}
.modal-save-buttons-top {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}

/**
 * Work Requests Table
 * ----------------------------------------------------------------------------
 */
.table--work-requests {
    flex: 1 1 auto;
}
.table-cell--work-request-type__value {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
a.table-cell-work-request-type--has-tag {
    color: #000000;
}
a.table-cell-work-request-type--has-no-tag {
    color: #000000;
}
.table-cell--work-request-work-order__value {
    display: flex;
    flex-direction: row; // column;
    gap: 5px;
}
.table-cell--work-request-work-order__value .work-order-status-label {
    font-size: 1rem;
    color: #585858;
    margin-top: 2px;
}

.work-request-status-with-flags-col {
    margin-top: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 35px;
    min-width: var(--work-request-status-min-width);
    @media screen and (max-width: $small-devices-width) {
        margin-top: 12px;
    }
}
.work-request-status-without-flags {
    padding-left: 35px;
    position: relative;
    min-width: var(--work-request-status-min-width);
}
.work-request-status-container-read-only {
    position: absolute;
    top: -6px;
    left: -6px;
    min-width: 204px;
}
.service-code-read-container {
    min-width: 239px;
}
.work-request-status-text-with-flags {
    margin-bottom: 10px;
}
.work-request-flags-item {
    background-color: var(--color-primary);
    border-radius: 500px;
    font-size: 1rem;
    color: #ffffff;
    padding: 2px 10px;
    margin-top: 2px;
    max-width: 114px;
    text-align: center;
    position: relative;
    left: -34px;
}

.work-request-status-link {
    cursor: pointer;
}

/**
 * Work Orders
 * ----------------------------------------------------------------------------
 */
.table--work-orders {
    flex: 1 1 auto;
}
.work-orders-quick-filters-mobile-filter-button {
    position: relative;
    height: var(--form-field-height);
    width: 100%;
    background: #292f38;
    border: 1px solid #292f38;
    border-radius: var(--form-field-border-radius);
    position: relative;
    cursor: pointer;
    padding: 10px 30px 10px 10px;
    outline: none;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: var(--form-field-font-size);
    color: #ffffff;
}
.work-orders-quick-filters-mobile-filter-button:after {
    content: "";
    position: absolute;
    right: 10px;
    width: 0px;
    height: 10px;
    top: 50%;
    transform: translateY(-3px);
    display: block;
    border-top: 6px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

/**
 * Loading Indicator
 * ----------------------------------------------------------------------------
 */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
.loading-indicator-cont {
    position: relative;
}
.loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: visibility ease 0.25s, opacity ease 0.25s;
    backdrop-filter: blur(3px);
    z-index: 2;
}
.loading-indicator > .loader {
    position: relative;
    width: 45px;
    height: 45px;
    border: 5px solid currentColor;
    border-top-color: transparent;
    border-radius: 50%;
    opacity: 1;
    transform: rotate(0deg);
    animation: loader infinite cubic-bezier(0.75, 0.25, 0.25, 0.75) 1s;
}
.loading-indicator--enter,
.loading-indicator--appear,
.loading-indicator--exit {
    opacity: 0;
    visibility: hidden;
}
.loading-indicator--enter-active,
.loading-indicator--appear-active,
.loading-indicator--enter-done,
.loading-indicator--appear-done {
    opacity: 1;
    visibility: visible;
}

/**
 * Manage Columns Modal
 * ----------------------------------------------------------------------------
 */
.modal--manage-columns .modal-body {
    padding: 0px;
}
.modal--manage-columns .default-columns-cont {
    border-bottom: 1px solid var(--color-tertiary);
    margin-bottom: 10px;
    padding: 10px 30px;
}
.modal--manage-columns__columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 24px;
    padding: 0px 30px 30px 30px;
}
@media screen and (max-width: 600px) {
    .modal--manage-columns__columns {
        grid-template-columns: auto;
    }
}

/**
 * Snackbar
 * ----------------------------------------------------------------------------
 */
// .snackbar-cont {
//     display: grid;
//     grid-template-columns: auto;
//     position: fixed;
//     top: 100%;
//     width: 100%;
//     left: 0;
//     z-index: 1;
//     align-content: end;
//     justify-content: end;
//     gap: 10px;
//     padding: 0px 20px;
//     pointer-events: none;
//     height: 0px;
// }
// .snackbar:last-child {
//     margin-bottom: 20px;
// }
.snackbar {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 4px;
    background: rgba(24, 30, 39, 0.9);
    padding: 6px 8px 6px 16px;
    color: #ffffff;
    z-index: 9;
    transition: opacity ease 300ms, transform ease 300ms;
    font-size: 1.3rem;
    line-height: 1.4em;
    max-width: 600px;
    display: flex;
    align-items: center;
    min-height: 48px;
    box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.5);
    min-width: 200px;
}
.snackbar-enter,
.snackbar-appear,
.snackbar-exit {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
}
.snackbar-enter-active,
.snackbar-appear-active,
.snackbar-enter-done,
.snackbar-appear-done {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
}
.snackbar-button-cont .button {
    text-transform: uppercase;
    font-weight: 700;
}
.snackbar--loader {
    padding-right: 16px;
}
.snackbar--loader:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 2px solid currentColor;
    border-top-color: transparent;
    border-radius: 50%;
    opacity: 0.75;
    margin-right: 10px;
    transform: rotate(0deg);
    animation: loader infinite cubic-bezier(0.75, 0.25, 0.25, 0.75) 1s;
}

/**
 * Filter Overlay
 * ----------------------------------------------------------------------------
 */
.filter-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
    left: 0;
    top: 0;
    z-index: 9;
    background: var(--color-backdrop);
    padding-bottom: 40px;
    opacity: 0;
    visibility: hidden;
    transition: visibility ease 300ms, opacity ease 300ms;
}
.filter-overlay__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: var(--form-column-gap);
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
    padding: 24px 20px 20px 20px;
    transform: translate(0px, -100%);
    transition: transform ease 300ms;
}
.filter-overlay--active {
    min-height: 100%;
    height: auto;
}
.filter-overlay--enter-active,
.filter-overlay--appear-active,
.filter-overlay--enter-done,
.filter-overlay--appear-done {
    opacity: 1;
    visibility: visible;
}
.filter-overlay--enter-active .filter-overlay__content,
.filter-overlay--appear-active .filter-overlay__content,
.filter-overlay--enter-done .filter-overlay__content,
.filter-overlay--appear-done .filter-overlay__content {
    transform: translate(0, 0);
}
.filter-overlay-group {
    display: flex;
    flex-direction: column;
}
.filter-overlay-group[data-columns="1"] {
    grid-column: span 1;
}
.filter-overlay-group[data-columns="2"] {
    grid-column: span 2;
}
.filter-overlay-group-label {
    color: var(--font-color-accent);
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    min-height: 11px;
}
.filter-overlay-group-column-cont {
    display: flex;
    column-gap: var(--form-field-hgap);
    flex: 1 1 0px;
}
.filter-overlay-group-column {
    display: flex;
    flex: 1 1 0px;
    width: 0px;
    flex-direction: column;
    row-gap: var(--form-field-vgap);
}
.filter-overlay-group-column--no-top-label {
    padding-top: 18px;
}
.filter-overlay-group-presets-cont {
    position: relative;
    display: grid;
    row-gap: var(--form-field-vgap);
}
.filter-overlay-group-presets-cont:before {
    content: "";
    display: block;
    position: absolute;
    top: -21px;
    bottom: 0px;
    left: -10px;
    width: 1px;
    background: var(--color-tertiary);
}
.filter-overlay-presets-button-cont {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0px, auto));
    gap: 10px;
}
.filter-overlay-presets-button-cont .button {
    padding: 0px;
}
.filter-overlay-apply-button {
    margin-top: auto;
    margin-left: auto;
}
.filter-overlay-reset-button {
    margin-top: -46px;
    margin-right: auto;
}
@media screen and (max-width: 1000px) {
    .filter-overlay__content {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .filter-overlay-group[data-columns] {
        grid-column: span 1;
    }
}
@media screen and (max-width: 800px) {
    .filter-overlay-group-column-cont[data-columns="2"] {
        display: grid;
        grid-template-columns: 100%;
        row-gap: var(--form-field-vgap);
    }
    .filter-overlay-group-column--no-top-label {
        padding-top: 0px;
    }
    .filter-overlay-group-column {
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    .filter-overlay__content {
        grid-template-columns: 100%;
    }
}

/**
 * Filter Overlay Extra
 * ----------------------------------------------------------------------------
 */
.upcoming-label-threshold-settings-cont {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    margin-top: 5px;
    font-size: 1.1rem;
    line-height: 1.4rem;
}
.upcoming-label-threshold-settings-cont a {
    text-decoration: none;
}
.filter-overlay-search-error {
    font-size: 1.1rem;
    color: var(--color-invalid);
}

/**
 * Form Section
 * ----------------------------------------------------------------------------
 */
.form-section-cont {
    --form-section-gap: 100px;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--form-section-gap);
}
.form-section {
    position: relative;
}
.form-section[data-header-alignment="side"] {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;
}
.form-section:nth-last-child(n + 2):after {
    content: "";
    position: absolute;
    top: calc(100% + calc(var(--form-section-gap) / 2));
    width: 100%;
    height: 2px;
    background-color: var(--color-secondary);
}
.form-section-header-cont {
    line-height: 2.2rem;
}
.form-section[data-header-alignment="top"] .form-section-header-cont {
    padding-bottom: 30px;
}
.form-section-header {
    font-size: 1.8rem;
    font-weight: 700;
}
.form-section-sub-header {
    font-size: 1.4rem;
    margin-top: 5px;
}
@media screen and (max-width: 1000px) {
    .form-section[data-header-alignment="side"] {
        display: block;
    }
    .form-section[data-header-alignment="side"] .form-section-header-cont {
        padding-bottom: 30px;
    }
}

/**
 * FormField
 * ----------------------------------------------------------------------------
 */
.form-field-label,
.form-field-group-label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
}

.form-field .form-field-label,
.form-field-group .form-field-group-label {
    margin-bottom: 4px;
}
.form-field .form-field-content {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
}

.form-field .form-field-content.form-field-content-red-stroke .text-area {
    border-color: red;
}

.form-field[data-direction="column"] .form-field-content {
    flex-direction: column;
    align-items: flex-start;
}
.form-field-group {
    width: 100%;
}
.form-field-group-content {
    background: var(--color-secondary);
    padding: 20px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.tag-form-field-group .form-field-group-content {
    background: var(--color-page-background);
    padding: 20px 20px 0 20px;
}

.form-field-group[data-theme="light"] .form-field-group-content {
    background: transparent;
    border: 1px solid var(--color-tertiary);
}
.form-field-note {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #707070;
}
.form-field[data-direction="column"] > .form-field-content > .text-input + .form-field-note {
    margin-top: -8px;
}
.form-field-note[data-type="error"] {
    color: #ff0b0b;
}
.form-field-note[data-inset="true"] {
    padding-left: 10px;
}
.form-field--threshold .form-field-content {
    display: grid;
    grid-template-columns: 1fr 82px;
    gap: 5px;
}
.PhysicalMeter .form-field-note[data-type="error"] {
    margin-top: -8px;
}

.form-field .collapsible-form-field-header {
    display: flex;
}

.form-field.double-span {
    grid-column: 1/2 span;
}

/**
 * FormDivider
 * ----------------------------------------------------------------------------
 */
.form-divider {
    background: var(--color-tertiary);
    height: 2px;
    width: 100%;
}
.form-divider[data-margin="small"] {
    margin: 10px 0px;
}
.form-divider[data-margin="medium"] {
    margin: 20px 0px;
}
.form-divider[data-margin="large"] {
    margin: 30px 0px;
}

/**
 * FormKeyValue
 * ----------------------------------------------------------------------------
 */
.form-key-value {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    line-height: 1.8rem;
    font-size: 1.4rem;
}
.form-key-value__key {
    color: #000000;
}
.form-key-value__value {
    color: #585858;
    text-align: end;
}
.form-key-value-list {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 14px 30px;
}
.form-key-value-list > .form-key-value {
    display: contents;
}

/**
 * TagFormField
 * ----------------------------------------------------------------------------
 */
.form-field.form-field--tag .form-field-content {
    display: flex;
    gap: 10px 32px;
}
@media screen and (max-width: 480px) {
    .form-field.form-field--tag .form-field-content {
        flex-direction: column;
        align-items: flex-start;
    }
}

/**
 * TextInput
 * ----------------------------------------------------------------------------
 */
.text-input {
    background: var(--color-secondary);
    height: var(--form-field-height);
    position: relative;
    border-radius: var(--form-field-border-radius);
    display: flex;
    align-items: center;
    width: 100%;
}
.text-input[data-theme="light"] {
    background: #ffffff;
}
.text-input .inlined-svg--search {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    width: 17px;
    height: 17px;
    color: #000000;
}

.text-input-field {
    flex: 0 0 100%;
    height: 100%;
    width: 100%;
    background: transparent;
    border: 1px solid var(--color-secondary);
    display: block;
    outline: none;
    border-radius: var(--form-field-border-radius);
    padding: var(--form-field-padding);
    font-size: var(--form-field-font-size);
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}
.text-input-field[type="search"] {
    padding: 10px 40px 10px 10px;
}
.text-input[data-type="money"] .text-input-field {
    padding: 10px 10px 10px calc(20px + 1ch);
}
.text-input[data-type="money"]:before {
    content: "$";
    display: block;
    left: 10px;
    position: absolute;
    font-size: 1.4rem;
}
.text-input--disabled {
    background: transparent;
}
.text-input--disabled .text-input-field {
    color: inherit;
    -webkit-text-fill-color: inherit;
    opacity: 1;
}
.text-input-field:focus-visible {
    border: 1px solid var(--color-action);
}
.text-input-field::-webkit-search-cancel-button,
.text-input-field::-webkit-outer-spin-button,
.text-input-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}

/**
 * SearchableTextInput
 * ----------------------------------------------------------------------------
 */
.searchable-text-input {
    width: 100%;
    position: relative;
    outline: none;
}
.searchable-text-input-overlay-cont {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% - 4px);
    padding-top: 4px;
    visibility: hidden;
    opacity: 0;
    background: var(--color-secondary);
    transition: visibility ease 250ms, opacity ease 250ms, transform ease 250ms;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 4px 4px;
    transform: translateY(-10px);
    z-index: 2;
}
.searchable-text-input-overlay {
    width: 100%;
    max-height: 250px;
}
.searchable-text-input--active .searchable-text-input-overlay-cont {
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
}
.searchable-text-input-options {
    padding-top: 6px;
    padding-bottom: 6px;
    max-height: 250px;
    overflow-y: auto;
    position: relative;
}
.searchable-text-input-option {
    display: block;
    width: 100%;
    text-align: start;
    background: none;
    outline: none;
    border: none;
    padding: 6px 10px;
    line-height: 1.8rem;
    font-size: 1.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    height: 30px;
}
.searchable-text-input-option:hover,
.searchable-text-input-option:focus-visible {
    background-color: #d0d0d0;
}
.searchable-text-input-option:nth-last-child(n + 2) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.searchable-text-input-no-matches-found {
    font-size: 1.4rem;
    padding: 8px 10px;
}
.searchable-text-input-loader {
    width: 100%;
    display: flex;
    align-items: center;
}
.searchable-text-input-loader:before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid var(--color-primary);
    border-top-color: transparent;
    border-radius: 50%;
    opacity: 0.75;
    margin-right: 10px;
    transform: rotate(0deg);
    animation: loader infinite cubic-bezier(0.75, 0.25, 0.25, 0.75) 1s;
}
.searchable-text-input-overlay .searchable-text-input-loader {
    padding: 8px 10px;
    justify-content: center;
}

/**
 * TextArea
 * ----------------------------------------------------------------------------
 */
.text-area {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    resize: none;
    min-height: 80px;
    border-radius: 4px;
    width: 100%;
    outline: none;
    font-family: inherit;
    font-size: 1.4rem;
    border: 1px solid transparent;
    background: transparent;
}
.text-area--hidden {
    position: absolute;
    left: -5000px;
    visibility: hidden;
    height: 0px;
}
.text-area[data-type="padded"] {
    padding: 19px;
    border: 1px solid var(--color-secondary);
    background: var(--color-secondary);
}
.text-area:focus-visible {
    border: 1px solid var(--color-action);
}

/**
 * MultiSelect
 * ----------------------------------------------------------------------------
 */
.multi-select {
    width: 100%;
    position: relative;
}
.multi-select-value {
    height: var(--form-field-height);
    width: 100%;
    background: var(--theme-background);
    border: 1px solid var(--theme-background);
    border-radius: var(--form-field-border-radius);
    position: relative;
    cursor: pointer;
    padding: 10px 30px 10px 10px;
    outline: none;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: var(--form-field-font-size);
    color: var(--theme-font-color);
}
.multi-select-value:focus-visible {
    border: 1px solid var(--theme-border-active);
}
.multi-select--overlay-active .multi-select-value {
    background: var(--theme-background-active);
}
.multi-select-value:after {
    content: "";
    position: absolute;
    right: 10px;
    width: 0px;
    height: 10px;
    top: 50%;
    transform: translateY(-3px);
    display: block;
    border-top: 6px solid var(--theme-font-color);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.multi-select-value-label {
    flex: 1 1 0px;
    width: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.multi-select-value-count {
    flex: 0 0 auto;
    margin-left: 10px;
}
.multi-select-overlay {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 10px);
    visibility: hidden;
    opacity: 0;
    background: var(--color-secondary);
    transition: visibility ease 250ms, opacity ease 250ms, transform ease 250ms;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    transform: translateY(-10px);
    z-index: 2;
}
.multi-select--overlay-active .multi-select-overlay {
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
}
.multi-select-search-cont {
    padding: 10px 10px 0px 10px;
}
.multi-select-overlay-button-cont {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    border-bottom: 1px solid var(--color-tertiary);
}
.multi-select-options {
    padding-top: 6px;
    padding-bottom: 6px;
    max-height: 250px;
    overflow-y: auto;
    position: relative;
}
.multi-select-option {
    padding: 6px 10px;
    gap: 12px;
}
.multi-select-option > .checkbox-input-mask {
    width: 18px;
    height: 18px;
    background-size: 11px 8px;
}
.multi-select-option > .checkbox-label {
    line-height: 1.8rem;
    font-size: 1.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.checkbox.checkbox--disabled > .checkbox-label {
    color: #848484;
}

.multi-select-no-matches-found {
    font-size: 1.4rem;
    padding: 8px 10px;
}
.multi-select-overlay .text-input {
    background: #ffffff;
    height: 36px;
}
.multi-select-overlay .text-input-field {
    border: 1px solid var(--color-tertiary);
}
.multi-select-overlay .inlined-svg--search {
    color: #707070;
}
.multi-select-loader {
    width: 100%;
    display: flex;
    align-items: center;
}
.multi-select-loader:before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid var(--color-primary);
    border-top-color: transparent;
    border-radius: 50%;
    opacity: 0.75;
    margin-right: 10px;
    transform: rotate(0deg);
    animation: loader infinite cubic-bezier(0.75, 0.25, 0.25, 0.75) 1s;
}
.multi-select-options .multi-select-loader {
    padding: 8px 10px;
    justify-content: center;
}
@media screen and (max-width: 370px) {
    .multi-select-overlay-button-cont .button {
        padding: 10px 8px;
        font-size: 1.2rem;
    }
}

/**
 * Select
 * ----------------------------------------------------------------------------
 */
.select {
    width: 100%;
    position: relative;
    display: grid;
    gap: 10px;
    align-items: center;
    height: 40px;
    background: var(--theme-background);
    border-radius: 4px;
}
.select[data-has-pre-content="true"] {
    grid-template-columns: min-content auto;
    padding-left: 10px;
}
.select[data-has-pre-content="true"] > select {
    padding-left: 0px;
}
.select-border {
    position: absolute;
    border: 1px solid var(--theme-border);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    pointer-events: none;
}
.select > select {
    height: 100%;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    background: transparent;
    color: var(--theme-font-color);
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    text-align: left;
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    padding: 10px 30px 10px 10px;
}
.select option {
    color: #000000;
}
.select:after {
    content: "";
    position: absolute;
    right: 10px;
    width: 0px;
    height: 10px;
    top: 50%;
    transform: translateY(-3px);
    display: block;
    border-top: 6px solid var(--theme-font-color);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.select > select:focus-visible ~ .select-border {
    border: 1px solid var(--theme-border-active);
}

/**
 * RoundedSelect
 * ----------------------------------------------------------------------------
 */
.select--rounded,
.select--rounded > .select-border {
    border-radius: 500px;
}

/**
 * Work Request Status Select
 * ----------------------------------------------------------------------------
 */
.work-request-status-indicator {
    width: 28px;
    height: 28px;
    border: 2px solid #707070;
    border-radius: 50%;
    position: absolute;
    left: 6px;
}
.work-request-status-indicator[data-status="workNotPerformed"] {
    border-color: #ff0b0b;
    background: #ff0b0b;
    background-repeat: no-repeat;
    background-position: center;
}
.work-request-status-indicator[data-status="Done-Flagged"] {
    border-color: #f4b824;
    background: #f4b824;
    background-repeat: no-repeat;
    background-position: center;
}
.work-request-status-indicator[data-status="done"] {
    border-color: #7bc131;
    background: #7bc131;
    background-image: url(images/checkmark.svg);
    background-repeat: no-repeat;
    background-position: center;
}
.work-request-status-indicator[data-status="declined"] {
    border-color: #ff0b0b;
    background: #ff0b0b;
    background-repeat: no-repeat;
    background-position: center;
}
.work-request-status-indicator[data-status="pending"] {
    border-color: #ffffff;
    background: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
}
.work-request-status-indicator[data-status="approved"] {
    border-color: #7bc131;
    background: #7bc131;
    background-image: url(images/checkmark.svg);
    background-repeat: no-repeat;
    background-position: center;
}
.select--work-request-status > select {
    min-width: 150px;
}
.select.select--work-request-status {
    padding-left: 0px;
    grid-template-columns: auto;
}
.select.select--work-request-status > select {
    padding-left: 44px;
}

.select--service-quote-status > select {
    min-width: 150px;
}
.select.select--service-quote-status {
    padding-left: 0px;
    grid-template-columns: auto;
}
.select.select--service-quote-status > select {
    padding-left: 44px;
}

/**
 * DatePicker
 * ----------------------------------------------------------------------------
 */
.date-picker {
    width: 100%;
}
.date-picker .MuiFormControl-root {
    width: 100%;
}
.date-picker .MuiInputBase-input {
    box-sizing: border-box;
    background: var(--theme-background);
    border: 1px solid var(--theme-border);
    outline: none;
    height: var(--form-field-height);
    border-radius: var(--form-field-border-radius);
    padding: var(--form-field-padding);
    font-size: var(--form-field-font-size);
    cursor: pointer;
    color: var(--theme-font-color);
}
.date-picker .MuiInputBase-input:focus-visible {
    border: 1px solid var(--theme-border-active);
}
.date-picker .MuiInput-underline:before,
.date-picker .MuiInput-underline:after {
    display: none;
}
.date-picker[data-disabled] .MuiInputBase-input {
    background: var(--theme-disabled-background);
    border: 1px solid var(--theme-disabled-border);
    cursor: default;
}
.MuiPickerDTTabs-tabs .MuiTabs-indicator {
    background-color: #ffffff;
}

/**
 * AttachmentList
 * ----------------------------------------------------------------------------
 */
div.attachment-list-item {
    overflow-x: hidden;
}
.attachment-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    position: relative;
    width: 100%;
}
.attachment-list-empty-item {
    font-size: 1.4rem;
    background: #f1f3f4;
    border-radius: 4px;
    padding: 19px;
    width: 100%;
    min-height: 50px;
    grid-column: 1 / span 6;
}
.attachment-item-context-menu {
    position: absolute;
    padding: 5px;
    border-radius: 3px;
    box-shadow: #000000;
    background-color: var(--color-tertiary);
    z-index: 100;
}
.attachment-item-context-menu-item:hover {
    background-color: var(--color-secondary);
}
.button.attachment-list-add-button {
    border: 1px solid #f1f3f4;
}
.attachment-list-add-button-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    opacity: 0;
    cursor: pointer;
}
.attachment-list-add-button:hover,
.attachment-list-add-button:focus-visible {
    background-color: #f1f3f4;
    color: #000000;
}
.attachment-list-add-button--disabled {
    color: var(--font-color-disabled);
    cursor: default;
}
.attachment-list-add-button--disabled:hover {
    color: var(--font-color-disabled);
    background: transparent;
}
.attachment-list-item {
    cursor: pointer;
}
.attachment-list-item-preview {
    display: block;
    height: 0px;
    padding-top: 100%;
    background-color: #f1f3f4;
    background-repeat: no-repeat;
    background-position: center;
    outline: none;
    border: 1px solid #f1f3f4;
    border-radius: var(--form-field-border-radius);
}
.attachment-list-item-preview:focus-visible {
    border: 1px solid var(--color-action);
}
.attachment-list-item-label {
    margin-top: 8px;
    font-size: 1.4rem;
}
@media screen and (max-width: 800px) {
    .attachment-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 600px) {
    .attachment-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

/**
 * Admin Page
 * ----------------------------------------------------------------------------
 */

.admin-page .content-wrapper {
    display: grid;
    grid-template-areas:
        "toolbar"
        "content";
    grid-template-columns: 1fr;
    column-gap: 20px;
    padding-bottom: 100px;
}

.admin-sidebar {
    grid-area: sidebar;
    display: inline-block;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 30px 30px 30px 30px;
    align-content: start;
}
.admin-sidebar-link {
    color: var(--color-action);
    font-size: 1.4rem;
    text-decoration: none;
    padding-right: 20px;
}
.admin-sidebar-link[data-active] {
    color: #000000;
}
.admin-sidebar-link:not(.admin-sidebar-link[href]) {
    opacity: 0.3;
}

@media screen and (max-width: 1000px) {
    .admin-page .content-wrapper {
        column-gap: 0px;
    }
}
@media screen and (max-width: 800px) {
    .admin-page .content-wrapper {
        grid-template-areas:
            "toolbar"
            "sidebar"
            "content";
        grid-template-columns: 1fr;
    }
    .admin-sidebar {
        background: var(--color-secondary);
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        padding: 30px;
    }
    .admin-sidebar-link {
        border-bottom: 2px solid transparent;
    }
    .admin-sidebar-link[data-active] {
        border-bottom: 2px solid #000000;
    }
}

/**
 * Service Request Creation Modal
 * ----------------------------------------------------------------------------
 */
.asset-label--mobile {
    font-size: 1.1em;
    font-weight: 600;
}
.service-request-creation-modal-asset-info {
    margin: 10px 0px;
}
.service-form-type-picker {
    min-width: calc((100% - (20px * 3)) / 4);
}
@media screen and (max-width: 800px) {
    .service-form-type-picker {
        min-width: calc((100% - (20px * 1)) / 2);
    }
}
@media screen and (max-width: 500px) {
    .service-form-type-picker {
        width: 100%;
    }
}
.service-request-creation-modal--alwaysOpen {
    border-radius: unset;
}

/**
 * Global Settings
 * ----------------------------------------------------------------------------
 */

.global-settings-grid.grid {
    grid-template-columns: repeat(3, minmax(0px, 300px));
    gap: 40px 40px;
}
.global-settings-grid.grid.mechanic-list {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    gap: 10px;
}
.form-section--aliases .form-section-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 120px;
}
.alias-cont {
    padding: 20px 0px 0px 0px;
    display: grid;
    gap: 10px 100px;
    grid-template-columns: auto 1fr;
    align-items: center;
    width: 100%;
}
.alias {
    display: contents;
    width: 100%;
}
.alias-label {
    font-size: 1.4rem;
}
.alias-value {
    position: relative;
    flex: 1 1 0px;
}
.alias-value:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/arrow-right.svg);
    left: -60px;
    top: calc(50% - 10px);
}
.mechanics-chip-cont {
    display: grid;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    overflow-y: auto;
    max-height: 17vh;
}
.mechanics-chip-cont .chip-value {
    text-overflow: ellipsis;
    overflow: hidden;
}
.threshold-value-type {
    flex: 0 0 75px;
}
@media screen and (max-width: 1000px) {
    .form-section--aliases .form-section-content {
        gap: 30px;
    }
    .alias-cont {
        gap: 10px 50px;
    }
    .alias-value:before {
        left: -35px;
    }
}
@media screen and (max-width: 640px) {
    .form-section--aliases .form-section-content {
        grid-template-columns: 1fr;
    }
    .mechanics-chip-cont {
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
}
@media screen and (max-width: 480px) {
    .global-settings-grid.grid {
        grid-template-columns: 1fr;
    }
}

/**
 * Tag
 * ----------------------------------------------------------------------------
 */
.tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    color: #585858;
    white-space: nowrap;
    line-height: normal;
}
.tag[data-theme="light"] {
    color: #ffffff;
}
.tag:before {
    content: "";
    display: block;
    width: 15px;
    height: 14px;
    background-image: url("../images/red-tag.svg");
    margin-bottom: 2px;
    background-repeat: no-repeat;
    background-position: center;
}
.tag--yellow:before {
    background-image: url("../images/yellow-tag.svg");
}

/**
 * Work Requests For Asset
 * ----------------------------------------------------------------------------
 */
.page--asset-work-requests .table {
    flex: 1 1 auto;
    width: 100%;
}
.table-cell--work-requests-name {
    display: flex;
    align-items: center;
    gap: 16px;
}
.table-cell--work-requests-name-link {
    background: none;
    border: none;
    outline: none;
    color: var(--color-action);
    font-size: 1.4rem;
    cursor: pointer;
    min-height: 40px;
    text-align: start;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.table-cell--work-requests-name-link:focus-visible {
    text-decoration: underline;
}

.page--asset-work-requests .circle-button--caret-left {
    width: 40px;
}

.page--asset-work-requests .circle-button {
    border-radius: 4px;
}

.page--asset-work-requests .circle-button.circle-button--is-interactive:hover,
.page--asset-work-requests .circle-button:focus-visible {
    background-color: rgba(255, 255, 255, 0.1);
    color: #000000;
}

.page--asset-work-requests .circle-button--caret-left.circle-button--is-interactive:hover .circle-button-circle {
    background-image: url("../images/caret-left.svg");
}

.page--asset-work-requests .circle-button--caret-left .circle-button-label {
    font-size: 0.9rem;
    color: #bac3c6;
}

.page--asset-work-requests
    .circle-button--caret-left[data-color="transparent"].circle-button--is-interactive:hover
    .circle-button-circle {
    border: 2px solid #ffffff;
}

.page--asset-work-requests .circle-button--caret-left[data-color="transparent"]:hover .circle-button-circle {
    border: 2px solid #76878d;
}

.page--asset-work-requests .circle-button--caret-left .circle-button-circle {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    background-image: url("../images/caret-left-grey.svg");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
}

.page--asset-work-requests .circle-button--add {
    width: 40px;
}

.page--asset-work-requests .circle-button.circle-button--add {
    border-radius: 4px;
}

.page--asset-work-requests .circle-button--add .circle-button-circle {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    background-image: url(../images/add-grey.svg);
    background-size: 10px;
    background-position: 50%;
    background-repeat: no-repeat;
}

.page--asset-work-requests .circle-button--add .circle-button-label {
    font-size: 0.9rem;
    color: #bac3c6;
}

/**
 * Flag Chip
 * ----------------------------------------------------------------------------
 */
.flag-chip {
    --flag-chip-margin: 8px;
    --flag-chip-border: 2px;
    grid-template-columns: 1fr;
    border-radius: 4px;
    position: relative;
    cursor: default;
}
.flag-chip[data-overlay="true"] {
    cursor: pointer;
}
.flag-chip-flag-cont {
    display: grid;
    grid-template-columns: max-content;
}
.flag-chip-overlay {
    display: none;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px;
    border-radius: 4px;
    background: #232832;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 20;
    box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.5);
}
.flag-chip-flag-cont > .flag-chip__flag {
    position: relative;
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
}
.flag-chip-flag-cont > .flag-chip__flag:nth-last-child(n + 2) {
    padding-right: var(--flag-chip-border);
}
.flag-chip-flag-cont > .flag-chip__flag:nth-last-child(n + 2):before {
    content: "";
    display: block;
    position: absolute;
    height: calc(100% + var(--flag-chip-border) * 2);
    width: 75%;
    background: #ffffff;
    border-radius: 500px;
    top: calc(-1 * var(--flag-chip-border));
    right: 0px;
}
.flag-chip__flag-label {
    position: relative;
    border-radius: 500px;
    font-size: 1.3rem;
    font-weight: 400;
    color: #000000;
    padding: 3px 10px;
    min-width: 80px;
    text-align: center;
    z-index: 1;
}
.flag-chip:hover[data-overlay="true"] .flag-chip-overlay {
    display: grid;
}
.flag-chip:hover[data-overlay="true"] .flag-chip-flag-cont .flag-chip__flag:nth-child(n + 2) {
    display: grid;
    display: none;
}

/**
 * Segmented Control
 * ----------------------------------------------------------------------------
 */
.segmented-control {
    --segmented-control-padding: 2px;
    --segmented-control-height: 36px;

    position: relative;
    min-height: var(--segmented-control-height);
    display: flex;
    background: var(--color-secondary);
    border-radius: 6px;
    padding: 2px;
}
.segmented-control-button {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    color: var(--color-primary);
    font-size: 1.4rem;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    transition: ease 0.3s color;
    text-align: center;
    align-items: center;
    flex: 1 1 auto;
    width: auto;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}
.segmented-control-button--active {
    color: var(--color-action);
}
.segmented-control-button:not(.segmented-control-button--active):hover,
.segmented-control-button:focus-visible {
    background: rgba(0, 0, 0, 0.07);
}
.segmented-control-highlight {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
    background: #ffffff;
    position: absolute;
    top: var(--segmented-control-padding);
    left: 0px;
    height: calc(100% - (var(--segmented-control-padding) * 2));
    border-radius: 4px;
    transform: translateX(0px);
}
@media screen and (max-width: 480px) {
    .segmented-control-button {
        padding: 8px;
    }
}

/**
 * Grid List
 * ----------------------------------------------------------------------------
 */
.grid-list {
    gap: 10px 24px;
    align-items: end;
}
.grid-list-item {
    border-bottom: 1px solid #d0d0d0;
    font-size: 1.4rem;
    line-height: 1.4em;
    padding: 7px 0px;
}

/**
 * Splash
 * ----------------------------------------------------------------------------
 */
#splash {
    display: flex;
    background: linear-gradient(225deg, var(--color-primary) 0%, #10141a 100%);
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    transition: opacity ease 0.25s, visibility ease 0.25s;
}
#splash.splash--loaded {
    opacity: 0;
    visibility: hidden;
}
#splash:after {
    content: "";
    display: block;
    width: 25vmin;
    height: 25vmin;
    max-width: 100px;
    max-height: 100px;
    background-image: url(../images/logo.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: pulse 2s ease infinite;
}

/**
 * Work Request Details
 * ----------------------------------------------------------------------------
 */
.modal--work-request-details .modal-sub-title {
    min-height: 1.6rem;
}

/**
 * Work Order
 * ----------------------------------------------------------------------------
 */
.work-order-asset-label--mobile {
    font-weight: 400;
}
.work-order-requests-table .table-row[data-id="totals-row"] {
    font-weight: 700;

    .table-cell__content {
        padding-left: 20px;
    }

    .total-row-label {
        .table-cell__content {
            padding-left: 10px;
        }
    }
}
.work-order-requests-table .table-row[data-id="totals-row-first"] {
    font-weight: 600;
    border: 0px;

    .table-cell__content {
        color: var(--font-color-disabled);        
    }

    .table-cell.table-cell--body{
        border-bottom: 0px;
    }
}
.work-order-requests-table .table-row[data-id="totals-row-second"] {
    font-weight: 700;    

    .total-row-label {
        .table-cell__content {
            padding-left: 10px;
        }
    }    
}


.work-order-requests-table .table-row[data-id="totals-row-approved"] {
    font-weight: 700;

    .table-cell__content {
        padding-left: 20px;
    }

    .total-row-label {
        .table-cell__content {
            padding-left: 10px;
        }
    }    
}
.work-order-requests-table .table-row[data-id="totals-row-declined"]  {
    font-weight: 600;
    border: 0px;
    
    .table-cell__content {
        padding-left: 20px;
        color: var(--color-invalid);        
    }

    .total-row-label {
        .table-cell__content {
            padding-left: 10px;
        }
    }
}

.work-order-requests-table .text-input-field {
    width: calc(40px + 9ch);
}
.task-description {
    font-size: 1.2rem;
    line-height: 1.6em;
}
.inspection-task .form-field-group-content {
    height: 100%;
    align-content: space-between;
}

.work-order-requests-table .table-cell {
    display: inline-table;
}

@media screen and (max-width: 480px) {
    .status-grid.grid[data-columns="2"] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

/**
 * Work Order table--header--work-requests
 * ----------------------------------------------------------------------------
 */
.table--header--work-requests .table-data-cont {
    min-height: 0px;
}
.table--header--work-requests .table-cell {
    border-bottom: 0px;
}
.table--header--work-requests .table-cell--body:not(.table-cell--no-hover-background):hover {
    background: none;
}
.table--header--work-requests .table-cell__content {
    padding: 2px 10px;
    font-size: 1.4rem;
    min-height: 5px;
}
.table--header--work-requests .table-cell--head__content {
    padding-bottom: 10px;
}
.table--header--work-requests .flag-chip__flag-label {
    padding: 0px;
}

/**
 * Manage Filter Presets
 * ----------------------------------------------------------------------------
 */
.manage-filter-presets .table-data-cont {
    min-height: 0px;
}
.manage-filter-presets-is-default-check {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--color-action);
}
.manage-filter-presets-is-default-check:before {
    content: "";
    display: block;
    background-image: url(../images/checkmark.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
}
.table.manage-filter-presets .table-cell--body:hover {
    background: none;
}
.manage-filter-presets .manage-filters-preset-name {
    word-break: break-word;
}

/**
 * page work order circle buttons
 * ----------------------------------------------------------------------------
 */
.page--work-order .circle-button--add {
    width: 40px;
}

.page--work-order .circle-button {
    border-radius: 4px;
}

.page--work-order .circle-button:hover,
.page--work-order .circle-button:focus-visible {
    background-color: rgba(255, 255, 255, 0.1);
    color: #000000;
}

.page--work-order .circle-button:hover .circle-button-circle {
    background-image: url("../images/add.svg");
}

.page--work-order .circle-button--add .circle-button-label {
    font-size: 0.9rem;
    color: #bac3c6;
}

.page--work-order .circle-button--add .circle-button-circle {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 2px;
    background-image: url("../images/add-grey.svg");
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
}

.page--work-order .circle-button--caret-left {
    width: 40px;
}

.page--work-order .circle-button {
    border-radius: 4px;
}

.page--work-order .circle-button.circle-button--is-interactive:hover,
.page--work-order .circle-button:focus-visible {
    background-color: rgba(255, 255, 255, 0.1);
    color: #000000;
}

.page--work-order .circle-button--caret-left.circle-button--is-interactive:hover .circle-button-circle {
    background-image: url("../images/caret-left.svg");
}

.page--work-order .circle-button--caret-left .circle-button-label {
    font-size: 0.9rem;
    color: #bac3c6;
}

.page--work-order
    .circle-button--caret-left[data-color="transparent"].circle-button--is-interactive:hover
    .circle-button-circle {
    border: 2px solid #ffffff;
}

.page--work-order .circle-button--caret-left[data-color="transparent"]:hover .circle-button-circle {
    border: 2px solid #76878d;
}

.page--work-order .circle-button.circle-button--caret-left .circle-button-circle {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    background-image: url("../images/caret-left-grey.svg");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
}

.page--work-order .MeterSyncInfo {
    height: 5px;
    width: 16px;
    position: absolute;
    top: 40px;
    right: 0px;
    color: pink !important;
}
/**
 * Dashboard Widget
 * ----------------------------------------------------------------------------
 */
.page--dashboard .content-wrapper {
    background: #f1f3f4;
}
.dashboard-widget-cont {
    padding: 80px 20px 20px 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    align-items: start;

    .recharts-surface {
        overflow: visible;
    }
}
.widget {
    display: flex;
    gap: 10px;
    flex-direction: column;
    max-width: 100%;
}
.widget-label {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    margin-bottom: 5px;
}
.widget-content {
    background: #ffffff;
    padding: 24px;
    border-top: 4px solid var(--color-accent);
    height: 300px;
    display: flex;
    max-width: 100%;
}
.work-order-status-widget .widget-content {
    gap: 20px;
    justify-content: space-between;
}
.work-order-status-widget-chart-cont {
    flex: 1 1 0px;
    width: 0px;
    display: flex;
    align-items: center;
    position: relative;
    max-width: 212px;
}
.work-order-status-widget-legend-cont {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-content: center;
}
.work-order-status-widget__legend-item {
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
}
.work-order-status-widget__legend-item > .label {
    text-decoration: underline;
    font-size: 1.4rem;
}
.work-order-status-widget__legend-item > .unauthorizedLabel {
    text-decoration: none;
    font-size: 1.4rem;
}

.work-order-status-widget__legend-item > .count {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 700;
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.multi-select.work-order-status-widget .multi-select-value
{    
    background: rgba(0, 0, 0, 0.05);    
}

.multi-select.work-order-status-widget .multi-select-value .inlined-svg
{    
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0px, -50%);
    pointer-events: none;    
    color: var(--color-action);
}

.multi-select.work-order-status-widget .multi-select-value:after
{    
    content: unset !important;
}

.multi-select.work-order-status-widget .multi-select-value-label{
    color: var(--color-action);
}

.multi-select.work-order-status-widget .multi-select-overlay {
    top: calc(-560%);
}

.red-yellow-tags-widget .widget-content {
    display: flex;
    flex-direction: column;
}
.red-yellow-tags-widget .graph-cont {
    position: relative;
}
.red-yellow-tags-widget .y-axis-label {
    position: absolute;
    font-size: 1rem;
    color: #acacac;
    transform: translate(20%, calc(-50% - 5px)) rotate(-90deg);
    top: 50%;
    right: 100%;
}
.red-yellow-tags-widget .overlay-graph {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.red-yellow-tags-widget-legend {
    display: flex;
    gap: 20px;
    margin-top: 24px;
    justify-content: flex-end;
}
.red-yellow-tags-widget-legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
}
.red-yellow-tags-widget-legend-item-text {
    font-size: 1rem;
}
.red-yellow-tags-widget-legend-item-indicator {
    height: 16px;
    width: 4px;
    border-radius: 500px;
}

.work-requests-completed-widget .widget-content {
    display: flex;
    flex-direction: column;
}
.work-requests-completed-widget .graph-cont {
    position: relative;
}
.work-requests-completed-widget .y-axis-label {
    position: absolute;
    font-size: 1rem;
    color: #acacac;
    transform: translate(20%, calc(-50% - 5px)) rotate(-90deg);
    top: 50%;
    right: 100%;
}
.work-requests-completed-widget-legend {
    display: flex;
    gap: 5px;
    margin-top: 24px;
    justify-content: flex-end;
}
.work-requests-completed-widget-legend-text {
    font-size: 1rem;
}
.work-requests-completed-widget-legend-indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

/**
 * Widget Select
 * ----------------------------------------------------------------------------
 */
.widget-select {
    background: rgba(0, 0, 0, 0.05);
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    padding-right: 8px;
    color: #247cf4;
    align-self: start;
}
.widget-select .inlined-svg {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0px, -50%);
    pointer-events: none;
}
.widget-select > select {
    height: 100%;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    background: transparent;
    color: var(--theme-font-color);
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    text-align: left;
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    padding: 10px 30px 10px 10px;
}
.widget-select option {
    color: #000000;
}
.widget-select:after {
    content: "";
    position: absolute;
    right: 10px;
    width: 0px;
    height: 10px;
    top: 50%;
    transform: translateY(-3px);
    display: block;
    border-top: 6px solid var(--theme-font-color);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.widget-select > select:focus-visible ~ .select-border {
    border: 1px solid var(--theme-border-active);
}

.widget.work-order-status-widget .widget-select {
    width: 100%;
}

div#root {
    overflow-x: hidden;
    overflow-y: hidden;
}
ul.ul-list-for-modal {
    margin: 30px;
}
ul.ul-list-for-modal li {
    padding-bottom: 10px;
}

//Parts List component
.parts-list-ul {
    padding: 20px;
}
.outlineInvalid input,
input.outlineInvalid {
    border: red solid 1px !important;
    border-radius: 5px !important;
}

a.external-link {
    text-decoration: none;
    color: var(--color-action);
}

.form-field .service-quote-verify-asset-label {
    padding-top: 24px;
}

.service-quote-item-grid-element {
    background-color: var(--color-secondary);
    border-radius: 5px;

    .form-field {
        margin: 10px;
    }

    .select--service-quote-status::after{
        display: none;
    }
    
}
/**
 * Adjust Service Request Modal component
 * ----------------------------------------------------------------------------
 */
.form-field.adjust-service-request-form-field .form-field-label {
    margin-bottom: 20px;
}

.service-performed-form-field .text-area {  
    height: 250px;
}
/**
 * 
 * ----------------------------------------------------------------------------
 */